import React from "react";

const TopAd = () => {
  return (
    <div className="w-full flex justify-start bg-light_pallete overflow-hidden">
      <h2 className="sale">Sale</h2>
      <h2 className="sale">Sale</h2>
      <h2 className="sale">Sale</h2>
      <h2 className="sale">Sale</h2>
      <h2 className="sale">Sale</h2>
      <h2 className="sale">Sale</h2>
      <h2 className="sale">Sale</h2>
      <h2 className="sale">Sale</h2>
      <h2 className="sale">Sale</h2>
      <h2 className="sale">Sale</h2>
      <h2 className="sale">Sale</h2>
      <h2 className="sale">Sale</h2>
      <h2 className="sale">Sale</h2>
      <h2 className="sale">Sale</h2>
      <h2 className="sale">Sale</h2>
      <h2 className="sale">Sale</h2>
      <h2 className="sale">Sale</h2>
      <h2 className="sale">Sale</h2>
      <h2 className="sale">Sale</h2>
      <h2 className="sale">Sale</h2>
      <h2 className="sale">Sale</h2>
      <h2 className="sale">Sale</h2>
      <h2 className="sale">Sale</h2>
      <h2 className="sale">Sale</h2>
      <h2 className="sale">Sale</h2>
      <h2 className="sale">Sale</h2>
      <h2 className="sale">Sale</h2>
      <h2 className="sale">Sale</h2>
    </div>
  );
};

export default TopAd;
